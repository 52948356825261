@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .active-word-one {
        background-color: rgb(88, 220, 246);
        text-decoration: underline;
        color: white;
    }
    .active-word-two {
        background-color: rgb(251, 143, 143);
        color: white;
    }
    .quotable-blue-fg {
        color: rgb(57, 116, 211);
    }
    .quotable-blue-bg {
        background-color: rgb(57, 116, 211);
    }
    .quotable-subtle-bg{
        background-color: rgb(244,247,252);
    }
  }